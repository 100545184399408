<template>
  <div>
    <div class="pititle">
      <el-form>
        <div class="litop">
          <el-row>
            <el-col :span="5">
              <div class="grid-content bg-purple">
                <el-form-item label="项目名称">
                  <el-input
                    v-model="form.name"
                    placeholder="请输入您的项目名称"
                    @blur="saveSearchInSession"
                  ></el-input>
                </el-form-item></div
            ></el-col>
            <el-col :span="5">
              <div class="grid-content bg-purple">
                <el-form-item label="批次编号">
                  <el-input
                    v-model="form.batch"
                    placeholder="请输入批次编号"
                    @blur="saveSearchInSession"
                  ></el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="5">
              <div class="grid-content bg-purple-light">
                <el-form-item label="测评机构">
                  <el-input
                    v-model="form.test_company"
                    placeholder="请输入测评机构"
                    @blur="saveSearchInSession"
                  ></el-input>
                </el-form-item></div
            ></el-col>
            <el-col :span="5">
              <div class="grid-content bg-purple">
                <el-form-item label="审查专家">
                  <el-input
                    v-model="form.build_company"
                    @blur="saveSearchInSession"
                    placeholder="请输入审查专家"
                  ></el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="2">
              <div class="grid-content bg-purple-light">
                <el-button type="primary" @click="search">查询</el-button>
                <span>共{{ totalCount }}条</span>
              </div></el-col
            >
            <el-col :span="2">
              <div class="grid-content bg-purple">
                <div class="prbtn">
                  <el-button type="primary" @click="showDistri"
                    >专家分配</el-button
                  >
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
    <div class="piconent">
      <!-- 表格 -->
      <div class="pitable">
        <el-table
          :data="tableData"
          stripe
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55" text-align="center">
          </el-table-column>
          <el-table-column
            label="批次编号"
            prop="batch"
            min-width="100"
            talign="center"
          >
          </el-table-column>
          <el-table-column
            label="项目名称"
            prop="name"
            min-width="200"
            align="center"
          >
            <template slot-scope="{ row }">
              <div @click="gotoStepInformation(row)" class="detailPush">
                <span>{{ row.name }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="建筑类型" align="center" min-width="100">
            <template slot-scope="{ row }">
              <span v-if="row.building_type == 1">公共建筑</span>
              <span v-if="row.building_type == 2">居住建筑</span>
            </template>
          </el-table-column>
          <el-table-column
            label="项目城市"
            align="center"
            prop="city"
            min-width="200"
          >
          </el-table-column>
          <el-table-column
            label="测评机构"
            align="center"
            prop="test_company"
            min-width="100"
          >
            <template slot-scope="{ row }">
              <span v-if="row.fname">{{ row.fname }}</span>
              <span v-else>{{ row.test_company }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="测评星级"
            align="center"
            prop="stars"
            min-width="100"
          >
            <template slot-scope="{ row }">
              <i
                v-for="item in row.stars"
                :key="item"
                class="el-icon-star-on"
              ></i>
            </template>
          </el-table-column>
          <el-table-column label="标识阶段" align="center" min-width="100">
            <template slot-scope="{ row }">
              <span v-if="row.stage == 1">能效评估</span>
              <span v-else-if="row.stage == 2">实测评估</span>
            </template>
          </el-table-column>
          <el-table-column
            label="申报时间"
            align="center"
            prop="created_at"
            min-width="150"
          >
            <template slot-scope="{ row }">
              <span>{{ row.created_at | formatDate("yyyy-MM-dd") }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="审查专家"
            align="center"
            prop="zname"
            min-width="100"
          >
          </el-table-column>
          <!-- <el-table-column label="修改专家" align="center" min-width="100">
            <template>
              <span style="color: #3086fb; font-weight: bold">修改</span>
            </template>
          </el-table-column> -->
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="pageTotal">
        <div class="block">
          <el-pagination
            :current-page="page"
            :page-count="totalPage"
            :page-size="limit"
            :total="totalCount"
            background
            layout=" prev, pager, next, total, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
      <div class="prbtnto">
        <el-button type="warning" @click="send_task">任务发送</el-button>
        <el-button
          type="warning"
          @click="send_export"
          style="margin-right: 10px"
          >导出分配表</el-button
        >
      </div>
    </div>
    <!-- 弹窗 -->
    <el-dialog title="专家分配" :visible.sync="dialogVisible" width="30%">
      <div class="tctenct">
        <div class="secexport">
          <div class="seeleft">
            选择专家：{{
              selected_expert
                .map((item) => {
                  return item.name;
                })
                .join(",")
            }}
          </div>
          <div class="seccright">
            当前选择项目数量：{{ checked_list.length }}
          </div>
        </div>
        <div class="searexport">
          搜索专家：<el-input
            placeholder="请输入内容"
            v-model="search_expert_name"
            clearable
            @keyup.native.enter="getExportList"
          >
          </el-input>
        </div>
        <div class="exportList">
          <el-checkbox-group
            v-model="selected_expert"
            :max="checked_list.length"
          >
            <el-checkbox
              v-for="item in expert_list"
              :key="item.id"
              :label="item"
              >{{ item.name }}</el-checkbox
            >
          </el-checkbox-group>
          <!--          <el-radio-group v-model="selected_expert">-->
          <!--            <el-radio v-for="item in expert_list" :key="item.id" :label="item.id">{{item.name}}</el-radio>-->
          <!--          </el-radio-group>-->
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dis_expert">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getProjectList,
  delProject,
  zplistApi,
  distributionExport,
  sendTask,
  sendexport,
} from "../../../../api/project";
export default {
  filters: {
    formatDate: function (timestamp, fmt) {
      if (fmt == undefined || fmt == "" || fmt == null) {
        fmt = "yyyy-MM-dd";
      }
      if (!timestamp) {
        return "";
      }
      let date = new Date(timestamp);
      // 第一步先替换年份 因为年份跟其他不一样是四位
      // let pat =
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, date.getFullYear());
      }
      // 第二步匹配其他，然后替换
      let obj = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds(),
      };
      for (let key in obj) {
        let pat = `(${key})`;
        if (new RegExp(pat).test(fmt)) {
          let str = obj[key] + "";
          // RegExp.$1  MM dd hh mm ss贪婪匹配
          fmt = fmt.replace(RegExp.$1, ("00" + str).substr(-2));
        }
      }
      return fmt;
    },
  },
  data() {
    return {
      form: {},
      dialogVisible: false,
      page: 1,
      limit: 10,
      totalCount: 0,
      totalPage: 1,
      tableData: [],
      //表格中选中的
      checked_list: [],

      expert_list: [],

      selected_expert: [],
      search_expert_name: "",
    };
  },
  mounted() {
    this.getSearchInSession();
    this.getList();
    this.getExportList();
  },
  methods: {
    gotoStepInformation(item) {
      if (item.batch && item.batch != "") {
        this.$router.push({
          path: "/formal/stepInformation",
          query: {
            id: item.id,
          },
        });
      }
    },
    saveSearchInSession() {
      if (this.search_form) {
        sessionStorage.setItem(
          "formalDis_search",
          JSON.stringify(this.search_form)
        );
      }
    },
    getSearchInSession() {
      let searchForm = sessionStorage.getItem("formalDis_search");
      if (searchForm && searchForm != "") {
        this.search_form = JSON.parse(searchForm);
      }
    },
    // 弹窗
    //  handleClose(done) {
    //     this.$confirm('确认关闭？')
    //       .then(_ => {
    //         done();
    //       })
    //       .catch(_ => {});
    //   },

    //任务发送
    send_task() {
      if (this.checked_list.length > 0) {
        for (let i = 0; i < this.checked_list.length; i++) {
          if (
            !this.checked_list[i].zname ||
            this.checked_list[i].zname.trim() == ""
          ) {
            this.$message.warning("每个任务都必须指定专家");
            return false;
          }
        }
        let ids = this.checked_list.map((item) => {
          return item.id;
        });
        let params = {
          ids: ids,
          token: this.$store.state.token,
        };
        let that = this;
        sendTask(params).then((res) => {
          that.$message.success(res.message);
          that.getList();
        });
      } else {
        this.$message.warning("请至少选择一个项目");
      }
    },
    //专家分配表
    send_export() {
      if (this.checked_list.length > 0) {
        let ids = this.checked_list.map((item) => {
          return item.id;
        });
        let params = {
          ids: ids.join(','),
          token: this.$store.state.token,
        };
        let that = this;
        sendexport(params).then((res) => {
          console.log("分配表", res);
          that.$message.success('下载成功');
          let fileName = '专家分配表.xlsx'
if (!res.data) {
     this.$message.warning(fileName + "下载失败！");
     return
}
if (typeof window.navigator.msSaveBlob !== 'undefined') {
  window.navigator.msSaveBlob(new Blob([res.data]), fileName);
} else {
  let url = window.URL.createObjectURL(new Blob([res.data]));
  let link = document.createElement('a');
  link.style.display = 'none';
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link); //下载完成移除元素
  window.URL.revokeObjectURL(url); //释放掉blob对象
}
        });
      } else {
        this.$message.warning("请至少选择一个项目");
      }
    },
    //分配专家
    dis_expert() {
      let ids = this.checked_list.map((item) => {
        return item.id;
      });
      let zids = this.selected_expert.map((item) => {
        return item.id;
      });
      let params = {
        export: zids,
        ids: ids,
        token: this.$store.state.token,
      };
      let that = this;
      distributionExport(params).then((res) => {
        console.log(res);
        that.$message.success("分配成功");
        that.getList();
        that.dialogVisible = false;
        that.selected_expert = [];
      });
    },

    showDistri() {
      if (this.checked_list.length > 0) {
        this.dialogVisible = true;
      } else {
        this.$message.warning("请至少选择一个项目");
      }
    },

    handleSelectionChange(val) {
      this.checked_list = val;
    },

    //获得专家
    getExportList() {
      let params = {
        page: 1,
        limit: 1000,
        token: this.$store.state.token,
      };
      if (this.search_expert_name != "") {
        params.name = this.search_expert_name;
      }
      let that = this;
      zplistApi(params).then((res) => {
        that.expert_list = res.data;
        that.expert_list.push({ id: 5372, name: "测试专家" });
      });
    },

    //删除数据
    check_del() {
      let that = this;
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let ids = this.checked_list.map((item) => {
            return item.id;
          });
          // console.log(ids);
          let params = {
            token: this.$store.state.token,
            id: ids,
          };
          delProject(params).then((res) => {
            console.log(res);
            that.getList();
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    //获得表格数据
    getList() {
      let that = this;

      let params = this.form;
      params.page = this.page;
      params.limit = this.limit;
      params.status = "4";
      params.token = this.$store.state.token;
      getProjectList(params)
        .then((res) => {
          // console.log(res);
          that.tableData = res.data;
          that.page = res.page;
          that.totalCount = res.totalCount;
          that.totalPage = res.totalPage;
          if (!that.tableData || that.tableData.length == 0) {
            this.$message.warning("暂无数据");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //搜索
    search() {
      this.page = 1;
      this.getList();
    },

    // 分页
    handleSizeChange(page) {
      this.limit = page;
      this.getList();
      //console.log(`每页 ${page} 条`)
    },
    handleCurrentChange(page) {
      //console.log(`当前页: ${page}`)
      this.page = page;
      this.getList();
    },
  },
};
</script>
<style scoped>
/deep/.el-checkbox {
  width: 100px;
}
/deep/.searexport .el-input {
  width: 70%;
}
/deep/.el-dialog__footer {
  text-align: center;
}
/deep/.el-dialog {
  border-radius: 5px;
}
/deep/.el-dialog__header {
  background-color: #3086fb;
  font-weight: bold;
  border-radius: 5px 5px 0 0;
}
/deep/.el-dialog__title {
  color: #ffffff;
}
/deep/.el-dialog__headerbtn .el-dialog__close {
  color: #ffffff;
}
/deep/.el-form-item__label {
  font-size: 18px;
}
/deep/ .litop .el-input {
  width: 245px;
}

/deep/ .litop .el-input__inner {
  width: 255px;
}
/deep/.el-table th.el-table__cell > .cell {
  color: #3086fb;
  font-weight: bold;
  font-size: 18px;
}
.secexport {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}
.exportList {
  margin: 10px 0;
}
.pitable {
  height: 78vh;
}
.litop {
  margin: 20px 24px;
}
.litop span {
  color: #3086fb;
  margin-left: 10px;
  line-height: 40px;
}
.prbtn {
  display: flex;
  flex-direction: row-reverse;
}
.pageTotal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 38px 0;
}
.prbtnto {
  display: flex;
  flex-direction: row-reverse;
  margin: 20px 0;
}
.detailPush {
  color: #3086fb;
  text-decoration: none;
  cursor: pointer;
}
</style>
